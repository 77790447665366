import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  "xml:space": "preserve",
  viewBox: "0 0 750 800"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      d: "M731.5 209.7c-3.9 131.9-33.1 250.7-101.1 358-53.1 83.8-123.8 148.8-213.5 192.2-8.2 4-16.9 7.1-24.4 12.2-13.6 9.3-26.2 6.8-40 .4C238.6 720.2 153.6 637 94.7 527.2 64.1 470.1 43.4 409 31.6 345 20.8 286.6 15 228.1 20.7 168.7c.7-7.7 2.9-11.8 10.5-14.8C142.9 110.4 254.3 66.5 366 23.2c5.2-2 12.6-1.6 18 .5 90 34.7 179.8 69.9 269.8 104.9 21.7 8.4 43.6 16.6 65.6 24.3 6.2 2.2 8.8 5.2 9.1 11.7.8 17 2.3 33.9 3 45.1z",
      style: {"fill":"#f8cf3b","stroke":"#000","stroke-width":"5","stroke-miterlimit":"10"}
    }, null, -1),
    _createElementVNode("path", {
      d: "M551.3 205.4 303.6 453.1 198.7 348.2l-59.1 59 105 104.9-.4.4 59 59.1 307.2-307.2z",
      style: {"fill":"#1d1d1b"}
    }, null, -1)
  ])))
}
export default { render: render }